// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$secret-web-app-primary: mat.define-palette(mat.$indigo-palette);
$secret-web-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$secret-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$secret-web-app-theme: mat.define-light-theme((color: (primary: $secret-web-app-primary,
        accent: $secret-web-app-accent,
        warn: $secret-web-app-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($secret-web-app-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// Selection 

*::selection {
  @apply bg-primary text-white shadow-lg
}

body {

  background-color: #1a1a1a;
}


// Card buttons position

.wp-grid-template-columns {
  grid-template-columns: max-content auto auto;
  display: grid;
}

@media only screen and (max-width: 600px) {
  .wp-grid-template-columns {
    display: block;
  }
}

// CSS Grid

.wp-grid-template-columns-2 {
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .wp-grid-template-columns-2 {
    display: block;
  }
}


// Select Icon 

select {
  background-image: url("../src/assets/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 7px top 52%;
  background-size: 24px auto;

  @apply appearance-none
}

@keyframes cardfadein {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// global 

* {
  transition: 0.2s ease;
  margin: 0;
  padding: 0;
}

// texts 

p {
  font-size: 16px;
  line-height: 140%;
  margin-top: 20px;
  @apply text-white
}

.bold {
  font-weight: 700;
}

// layout 

.boxed {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 30px;
}

.full-width {
  width: 100%;
}

html,
body {
  height: 100%;
}

@font-face {
  font-family: 'RobotoFlex';
  src: url('assets/fonts/robotoflex.ttf') format('truetype-variations');
  font-weight: 1 999;

}

body {
  margin: 0;
}

* {
  font-family: RobotoFlex, "Helvetica Neue", sans-serif;

}

// input fields  {
*:focus {
  outline: #00000000 2px solid !important;
  border-color: #ea4d3e !important;
}

// expired item 

.expired {
  filter: grayscale(1) opacity(0.4);
}

.drop-shadow-2xl {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.05) !important;
  --tw-drop-shadow: none;
}

// toast

.toast-message {
  // background-color: rgba(0, 0, 0, 0.7);
  //   background-color: rgba(53, 179, 114, 0.7);
  //   color: white;
  //   padding: 10px 20px;
  //   border-radius: 5px;
  //   backdrop-filter: blur(5px);
  // width: fit-content;
  font-size: 16px;
}

// .toast-container {
//   background-color: rgba(0, 0, 0, 0.0);
// }

// .toast-container .ng-trigger {
//   background-color: rgba(0, 0, 0, 0.0);
//   box-shadow: none !important;
//   padding: 0 !important;

// }

// card 

.s-card {
  animation: cardfadein 0.5s ease 0s 1 normal forwards;
  border-radius: 10px;
  box-shadow: #bdbdbd49 0px 0px 15px 0px;
  padding: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto
}

.s-card-title {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.s-card-description {
  font-size: 16px;
  color: gray;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px
}

// form 

.s-label {
  font-size: 16px;
  color: gray;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
  margin-bottom: -5px;
}

.s-input,
.s-select {
  display: block;
  width: 100%;
  border: lightgray 1.5px solid;
  background-color: white;
  font-weight: 500;
  padding: 10px;
  caret-color: #ea4d3e;
  margin-top: 10px;
  font-size: 16px;
}


.s-input:not(:placeholder-shown).ng-invalid,
.ng-invalid.s-input {
  color: #ea4d3e;
  border: 1.5px solid #f6c6c2;
  background-color: #fff1ef;
}

.ng-pristine.s-input {
  border: lightgray 1.5px solid;
  background-color: white;
}

.s-form-error-message {
  color: #e1ada9;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin-top: 5px;
}

app-show-hide-password:has(.ng-invalid) {
  display: block !important;
}

app-show-hide-password:has(.ng-invalid) .s-type-toggle {
  display: none;
}


.read {
  @apply bg-light
}

.s-card:has(.s-input:focus, .s-select:focus) {
  box-shadow: #88888825 0px 5px 15px 0px;
}


.s-select {
  cursor: pointer;
  max-height: 46.22px;

}

form .s-button-primary,
form .s-button-secondary {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {

  form .s-button-primary,
  form .s-button-secondary {
    width: 100% !important;
    text-align: center;
    margin-top: 0;
  }

  form .s-button-primary:first-of-type,
  form .s-button-secondary {
    margin-top: 20px;
  }
}

// spacing 

.s-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.s-next-to {
  display: flex;
  align-items: center;
  gap: 10px
}

@media screen and (max-width: 600px) {
  .s-title {
    margin-bottom: 30px;
  }

  .s-undertitle {
    display: none;
  }

  .s-space-between,
  .s-next-to {
    flex-direction: column;
  }
}

// buttons 

a,
button {
  cursor: pointer;
}

.s-button-primary {
  text-decoration: none !important;
  border-radius: 99px;
  font-weight: 500;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @apply bg-primary
}

.s-button-primary:focus-visible,
.s-button-secondary:focus-visible {
  border: 2px solid black !important
}

.s-button-primary:hover {
  @apply bg-dark
}

.s-button-secondary {
  font-weight: 500;
  color: #ea4d3e;
  display: block;
  font-size: 18px;
  line-height: 110%;
  transition: 0.2s;
  text-align: center;
}

.s-button-secondary:hover {
  @apply text-dark
}

// background 

.s-boxed {
  @apply bg-light;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 30px;
  padding-right: 30px;
}

.s-sidebar-grid .s-boxed {
  padding: 30px;
}

// texts 

.s-title {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.s-sidebar-grid .s-title {
  text-align: left;

}

.s-sidebar-title {
  margin-bottom: 25px;
}



.s-undertitle {
  text-align: center;
  font-size: 18px;
  color: grey;
  font-weight: 500;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  text-wrap: balance;
}


.s-sidebar-grid .s-undertitle {
  text-align: left;

}

.s-tiny-text {
  display: block;
  margin-top: 20px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: gray
}

.s-badge {
  padding: 5px 15px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  color: grey;
  margin: 0;
  margin-right: 10px;
  font-weight: 500;
  box-shadow: #bdbdbd38 0px 2px 5px 0px;

}

@media screen and (max-width: 600px) {
  .s-title {
    font-size: 28px;
  }
}

// sidebar grid 

.s-sidebar-grid {
  display: grid;
  grid-template-columns: 205px 1fr;
  height: 100dvh;
  animation: smallerwidth 0.5s;
  @apply bg-light;

  @keyframes smallerwidth {
    0% {
      grid-template-columns: 280px 1fr;
    }

    80% {
      grid-template-columns: 280px 1fr;
    }

    100% {
      grid-template-columns: 205px 1fr;
    }
  }


  .s-sidebar .s-logo {
    scale: 0.8;
    transform: translateX(-18px);
    animation: smallerlogo 0.5s;
  }
}

@keyframes smallerlogo {
  0% {
    transform: translateX(0px);
    scale: 1
  }

  80% {
    transform: translateX(0px);
    scale: 1
  }

  100% {
    transform: translateX(-18px);
    scale: 0.8;
  }
}

.s-sidebar-grid:has(.s-sidebar:hover) {
  grid-template-columns: 280px 1fr !important;

  .s-sidebar .s-logo {
    scale: 1 !important;
    transform: translateX(0px) !important;

  }
}

@media (max-width: 900px) {
  .s-sidebar-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

  }

  .s-sidebar-grid app-sidebar {
    grid-area: 2/1;
  }
}


.s-sidebar-grid {
  height: 100dvh !important;
  overflow: hidden;

  .bg-white {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100dvh !important;
    padding-bottom: 80px !important;

    @apply bg-light
  }
}